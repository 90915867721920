import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import DataTable from './table'

const Read = () => {
  //isLoading,
  const { user, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const [userMetadata, setUserMetadata] = useState(true);

  const getUserMetadata = async () => {
    const domain = "tribudelattre.auth0.com";
    try {
      /*
      https://auth0.com/docs/troubleshoot/authentication-issues/renew-tokens-when-using-safari
      */
      const accessToken = await getAccessTokenSilently({
        audience: `https://tribudelattre/dev/api/banque/v1`
      });
      console.log(accessToken);
      const userDetailsByIdUrl = `/secure/data/courtage?id=106`;

      const metadataResponse = await fetch(userDetailsByIdUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          ContentType: `application/json`
        },
      });

      const user_metadata = await metadataResponse.json();
      setUserMetadata(user_metadata);
      return user_metadata;
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if(isLoading==false) {getUserMetadata();}

  }, [isLoading, isAuthenticated, getAccessTokenSilently]);

  if (!isAuthenticated) {
      return <div>Authentification en cours...</div>;
  }

  if (userMetadata && userMetadata=="No auth") {
    return <div>Authentification nécessaire...</div>;
  }

  return (
    <div>

      <DataTable rows={userMetadata}/>

    </div>
  );
};

export default Read;
