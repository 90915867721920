import * as React from 'react';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import clsx from 'clsx';
import Box from '@mui/material/Box';

const columns: GridColDef[] = [
  { field: 'ticker', headerName: 'ticket', align:'left', width: 65 },
  { field: 'quantite', headerName: 'Qté', align:'right', width: 45,
    type: "number"},
  { field: 'cours_marche', headerName: 'Marché', align:'right',  width: 75,
    type: "number",
    valueFormatter: ({ value }) => `${value} $`,
    valueGetter: (params: GridValueGetterParams) =>
      `${parseFloat(params.row.cours_marche).toFixed(3) || '-'}`,
  },
  {
  field: 'variation',
  headerName: 'Variation',
  description: 'This column has a value getter and is not sortable.',
  sortable: true,
  type: "number",
  align:'right',
  width: 75,
  valueGetter: (params: GridValueGetterParams) =>
    `${parseFloat(params.row.cours_marche - params.row.cours_marche_precedent).toFixed(3) || '-'}`,
  valueFormatter: ({ value }) => `${value} $`,
  cellClassName: (params) => {
          if (params.value == null) {
            return '';
          }

          return clsx('super-app', {
            negative: params.value < 0,
            positive: params.value > 0,
          });
        },
},
{
  field: 'bilan',
  headerName: 'Bilan',
  description: 'This column has a value getter and is not sortable.',
  sortable: true,
  type: "number",
  align:'right',

  valueGetter: (params: GridValueGetterParams) =>
    `${parseFloat(params.row.valeur_marche - params.row.cours_achat * params.row.quantite).toFixed(3) || '-'}`,
  valueFormatter: ({ value }) => `${value} $`,
  cellClassName: (params) => {
    if (params.value == null) {
      return '';
    }
    return clsx('super-app', {
      negative: params.value < 0,
      positive: params.value > 0,
    });
  },
},
];

export default function DataTable( rows ) {
  if (rows.rows !=null && rows.rows.data != null) {
    return (
        <Box
          sx={{
            height: 670,
            width: '100%',
            '& .super-app.negative': {
              color: '#d47483',
              fontWeight: '600',
            },
            '& .super-app.positive': {
              color: 'rgba(157, 255, 118, 0.49)',
              fontWeight: '600',
            },
          }}
        >
        <h4>Total: {rows.rows.aggregation.total_marche}$ | Gain: {rows.rows.aggregation.total_portefeuille_variation}$ | &#916;March&eacute; {rows.rows.aggregation.total_marche_variation}$</h4>

        <DataGrid
          initialState={{
            sorting: {
              sortModel: [{ field: 'variation', sort: 'asc' }],
            },
          }}
          rowHeight={25}
          rows={rows.rows.data}
          columns={columns}
          pageSize={23}
          rowsPerPageOptions={[23]}
        />
        </Box>

    );
  } else {
    return (
      <div>En attente des données...</div>
    );
  }


}
