import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from "@auth0/auth0-react";
import LoginButton from './AuthLogin/login'
import LogoutButton from './AuthLogin/logout'

const refreshPage = () => {
  console.log("Refresh");
  window.location.reload(false);
}

ReactDOM.render(

  <Auth0Provider
      domain="tribudelattre-dev.us.auth0.com"
      clientId="eHKzjaBUkF63auXqmHEiyT1nbPWEdohG"
      redirectUri={window.location.origin}
      useRefreshTokens={true}
      cacheLocation="localstorage"
      audience="https://tribudelattre/dev/api/banque/v1"
      scope="xxxx"
  >
    <LoginButton/>
    <LogoutButton/>
    <button onClick={refreshPage}>Recharger!</button>
     {new Date().toLocaleString()}
    <App />

  </Auth0Provider>,
  document.getElementById("root")
);
