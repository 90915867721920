import logo from './logo.svg';
import './App.css';
import Read from './courtage/read'
import Profile from './AuthLogin/profile'
import LoginButton from './AuthLogin/login'
import LogoutButton from './AuthLogin/logout'
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
  typography: {
    fontSize: 12
  },
});

function App() {

  return (
      <ThemeProvider theme={darkTheme}>
          <CssBaseline />
          <main><Read/></main>
      </ThemeProvider>

  );
}


export default App;
